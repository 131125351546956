.animation-fadeIn {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

@keyframes fadeIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.animation-slideDown {
  animation-name: slideDown;
  animation-duration: 1s;
  animation-timing-function: ease;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(8%);
  }
  65% {
    transform: translateY(-4%);
  }
  80% {
    transform: translateY(4%);
  }
  95% {
    transform: translateY(-2%);
  }
  100% {
    transform: translateY(0%);
  }
}

.animation-slideUp {
  animation-name: slideUp;
  animation-duration: 1s;
  animation-timing-function: ease;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }

  50% {
    transform: translateY(-8%);
  }
  65% {
    transform: translateY(4%);
  }
  80% {
    transform: translateY(-4%);
  }
  95% {
    transform: translateY(2%);
  }
  100% {
    transform: translateY(0%);
  }
}

.animation-fadeIn360 {
  animation-name: fadeIn360;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
}

@keyframes fadeIn360 {
  0% {
    transform: rotate(0deg) scale(1.3);
    opacity: 0;
  }
  100% {
    transform: rotate(360deg) scale(1);
    opacity: 1;
  }
}

.animation-fadeInRight {
  animation-name: fadeInRight;
  animation-duration: 0.75s;
  animation-timing-function: ease-out;
}

@keyframes fadeInRight {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.animation-fadeInLeft {
  animation-name: fadeInLeft;
  animation-duration: 0.75s;
  animation-timing-function: ease-out;
}

@keyframes fadeInLeft {
  0% {
    transform: translateX(+100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.animation-hatch {
  animation-name: hatch;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
}

@keyframes hatch {
  0% {
    transform: rotate(0deg) scaleY(0.6);
  }
  20% {
    transform: rotate(-2deg) scaleY(1.05);
  }
  35% {
    transform: rotate(2deg) scaleY(1);
  }
  50% {
    transform: rotate(-2deg);
  }
  65% {
    transform: rotate(1deg);
  }
  80% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.animation-stretchLeft {
  animation-name: stretchLeft;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
}

@keyframes stretchLeft {
  0% {
    transform: scaleX(0.3);
  }
  40% {
    transform: scaleX(1.02);
  }
  60% {
    transform: scaleX(0.98);
  }
  80% {
    transform: scaleX(1.01);
  }
  100% {
    transform: scaleX(0.98);
  }
  80% {
    transform: scaleX(1.01);
  }
  100% {
    transform: scaleX(1);
  }
}

.animation-stretchRight {
  animation-name: stretchRight;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  transform-origin: 0 0;
  -ms-transform-origin: 0 0;
}

@keyframes stretchRight {
  0% {
    transform: scaleX(0.3);
  }

  40% {
    transform: scaleX(1.02);
  }
  60% {
    transform: scaleX(0.98);
  }
  80% {
    transform: scaleX(1.01);
  }
  100% {
    transform: scaleX(0.98);
  }
  80% {
    transform: scaleX(1.01);
  }
  100% {
    transform: scaleX(1);
  }
}

.animation-bigEntrance {
  animation-name: bigEntrance;
  animation-duration: 1.6s;
  animation-timing-function: ease-out;
}

@keyframes bigEntrance {
  0% {
    transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
    opacity: 0.2;
  }

  30% {
    transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
    opacity: 1;
  }
  45% {
    transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  60% {
    transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  75% {
    transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  90% {
    transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  100% {
    transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
}

.animation-delay-1 {
  animation-delay: 0.5s;
}

.animation-delay-2 {
  animation-delay: 1s;
}

.animation-delay-3 {
  animation-delay: 1.5s;
}
