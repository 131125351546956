body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #262e35;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div, span, p, h1, h2, h3, h4, h5, h6, button {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, ul, li {
  margin: 0;
  padding: 0;
}

/* DESKTOP */

.irPtrP,.sc-fubCfw.irPtrP {
  background: transparent;
}
.sc-pFZIQ.gbgfMs {
  border-radius: 10px;
  background: rgb(175 65 148);
  color: white !important;
}
.sc-pFZIQ.gbgfMs li a {
  color: white !important;
}
.sc-iqHYGH.hSaKRS {
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 64px;
  font-family: "system-ui";
  background: black;
  border: 1px solid white;
}
body {
  font-family: "system-ui";
  background: black;
}
.sc-jrAGrp.dXWCyY {
  margin-top: 10px;
}
button.sc-dlfnbm.bcaJjD.lg,a.sc-gKsewC.gnwqMi.lg {
  border-radius: 10px;
  border: solid 1px white;
  background: transparent;
  color: rgb(175 65 148);
  font-size: 21px;
}

button.sc-dlfnbm.bcaJjD.md.disabled{
  border-radius: 10px;
  border: solid 1px white;
  background: transparent;
  color: rgb(175 65 148);
  font-size: 21px;
}

button {
  border-radius: 10px;
}

button.sc-dlfnbm.bcaJjD.md {
  border-radius: 10px;
  border: solid 1px white;
  background: transparent;
  color: rgb(175 65 148);
  font-size: 21px;
}

div#root,html.absolute.top.right.bottom.left.max-width.max-height.background-color-main.font-color-main.font-primary {
  background: black;
}

/* MOBILE */

@media only screen and (max-width: 768px) {

  html.absolute.top.right.bottom.left.max-width.max-height.background-color-main.font-color-main.font-primary {
    padding: 11px;
  }

  .sc-fubCfw.irPtrP {
    font-size: 11px;
  }

  .sc-pFZIQ.gbgfMs li a {
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: underline;
  }

  h1.sc-bdfBwQ.cIKpxU {
    font-size: 33px;
  }

  .sc-gsTCUz.bhdLno {
    display: none;
  }

}